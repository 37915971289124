<template>
  <div class="row">
    <div class="col-md-12">
      <div :class="cols">
        <Product
          :product="product"
          :key="`product-${product._id}`"
          v-for="product of productList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";

import Product from "@/components/Base/Product.vue";
import settings from "@/config";
import useFetch from "@/composables/useFetch";

export default defineComponent({
  name: "ProductList",
  components: { Product },
  props: {
    columns: { type: Number, default: 4 },
    filters: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const { fetchData } = useFetch();

    watch(
      () => props.filters,
      () => {
        filterProductList();
      },
      { deep: true }
    );

    const cols = computed(() => {
      return `product-grid row row-cols-1 row-cols-sm-2 row-cols-md-${props.columns}`;
    });

    const productList = ref([]);

    const filterProductList = async () => {
      try {
        const filters = {};
        if (
          props.filters?.categories &&
          Array.isArray(props.filters.categories) &&
          props.filters.categories.length > 0
        ) {
          filters["categories"] = props.filters.categories;
        }
        if (
          props.filters?.condition &&
          Array.isArray(props.filters.condition) &&
          props.filters.condition.length > 0
        ) {
          filters["condition"] = props.filters.condition;
        }

        const response = await fetchData({
          url: `${settings.API}/search/product/list`,
          method: "POST",
          body: { filters: filters, search: props.filters?.search || null },
        });

        productList.value = response.data.records;
      } catch (err) {
        console.log(err);
      }
    };

    filterProductList();

    return { productList, cols };
  },
});
</script>
