<template>
  <div>
    <BannerContainer />
    <section class="pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="line-height: normal">
            <h2 class="section-title">Encuentra lo que necesitas</h2>
            <small class="text-muted">
              Obtén todo lo que necesitas en un solo lugar
            </small>
          </div>
        </div>
        <hr />
        <Categories columns="6" />
      </div>
    </section>

    <section class="pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="section-header d-flex flex-wrap justify-content-between my-4"
            >
              <div style="line-height: normal">
                <h2 class="section-title">Productos Destacados</h2>
                <small class="text-muted">
                  Encuentra nuestras mejores ofertas y promociones
                </small>
              </div>

              <div class="d-flex align-items-center">
                <router-link
                  :to="{ name: 'search' }"
                  class="btn btn-primary rounded-1"
                  >Ver todos</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <hr />
        <ProductList columns="5" />
      </div>
    </section>
  </div>
</template>

<script>
import BannerContainer from "@/components/Base/BannerContainer.vue";
import ProductList from "@/components/Base/ProductList.vue";
import Categories from "@/components/Base/Categories.vue";

export default {
  name: "HomeView",
  components: {
    BannerContainer,
    ProductList,
    Categories,
  },
};
</script>
