import { defineStore } from "pinia";
import { reactive } from "vue";
import settings from "@/config";
import useFetch from "@/composables/useFetch";
const { fetchData } = useFetch();

export const useCategoriesStore = defineStore("categories", {
  state: () => ({
    categories: reactive([]),
  }),
  actions: {
    async loadCategories() {
      try {
        const response = await fetchData({
          url: `${settings.API}/search/category/list`,
          method: "POST",
          body: {},
        });
        this.categories = response.data.records;
      } catch (err) {
        console.log(err);
      }
    },
  },
});
