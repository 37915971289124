<template>
  <div class="row">
    <div class="col-md-12">
      <div :class="cols">
        <router-link
          class="nav-link text-center"
          style="margin-right: 30px; margin-bottom: 30px"
          :to="{ name: 'search', query: { categories: [category._id] } }"
          :key="`category-${category.id}`"
          v-for="category of categoriesStore.categories"
        >
          <img
            :src="`${settings.API}/file/categories/${category._id}/${category.files[0]}`"
            class="category-image"
            alt="Category Thumbnail"
          />
          <h4 class="fs-6 mt-3 fw-normal category-title">
            {{ category.name }}
          </h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useCategoriesStore } from "@/store/useCategoriesStore.js";
import settings from "@/config";

export default defineComponent({
  name: "Categories",
  props: {
    columns: { type: String, default: 4 },
  },
  setup(props) {
    const categoriesStore = useCategoriesStore();

    const cols = computed(() => {
      return `row row-cols-1 row-cols-sm-2 row-cols-md-${props.columns}`;
    });

    return { categoriesStore, cols, settings };
  },
});
</script>
<style scoped>
.category-image {
  height: 120px;
  width: 120px;
  background: #e30613;
  border-radius: 50% !important;
  cursor: pointer;
}

.category-image:hover {
  box-shadow: 0px 0px 44px rgba(34, 34, 34, 0.288);
  background: none;
  border: 5px solid #005ca9; /* Grosor y color del borde */
  border-radius: 8px; /* Opcional: redondea las esquinas */
  padding: 2px; /* Opcional: separa el contenido del borde */
}

.category-title {
  text-transform: capitalize;
}
</style>
