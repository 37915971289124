<template>
  <div class="col">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <defs>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          id="star-full"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m3.1 11.3l3.6 3.3l-1 4.6c-.1.6.1 1.2.6 1.5c.2.2.5.3.8.3c.2 0 .4 0 .6-.1c0 0 .1 0 .1-.1l4.1-2.3l4.1 2.3s.1 0 .1.1c.5.2 1.1.2 1.5-.1c.5-.3.7-.9.6-1.5l-1-4.6c.4-.3 1-.9 1.6-1.5l1.9-1.7l.1-.1c.4-.4.5-1 .3-1.5s-.6-.9-1.2-1h-.1l-4.7-.5l-1.9-4.3s0-.1-.1-.1c-.1-.7-.6-1-1.1-1c-.5 0-1 .3-1.3.8c0 0 0 .1-.1.1L8.7 8.2L4 8.7h-.1c-.5.1-1 .5-1.2 1c-.1.6 0 1.2.4 1.6"
          />
        </symbol>

        <symbol
          xmlns="http://www.w3.org/2000/svg"
          id="star-half"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m3.1 11.3l3.6 3.3l-1 4.6c-.1.6.1 1.2.6 1.5c.2.2.5.3.8.3c.2 0 .4 0 .6-.1c0 0 .1 0 .1-.1l4.1-2.3l4.1 2.3s.1 0 .1.1c.5.2 1.1.2 1.5-.1c.5-.3.7-.9.6-1.5l-1-4.6c.4-.3 1-.9 1.6-1.5l1.9-1.7l.1-.1c.4-.4.5-1 .3-1.5s-.6-.9-1.2-1h-.1l-4.7-.5l-1.9-4.3s0-.1-.1-.1c-.1-.7-.6-1-1.1-1c-.5 0-1 .3-1.3.8c0 0 0 .1-.1.1L8.7 8.2L4 8.7h-.1c-.5.1-1 .5-1.2 1c-.1.6 0 1.2.4 1.6m8.9 5V5.8l1.7 3.8c.1.3.5.5.8.6l4.2.5l-3.1 2.8c-.3.2-.4.6-.3 1c0 .2.5 2.2.8 4.1l-3.6-2.1c-.2-.2-.3-.2-.5-.2"
          />
        </symbol>

        <symbol
          xmlns="http://www.w3.org/2000/svg"
          id="cart"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M8.5 19a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 8.5 19ZM19 16H7a1 1 0 0 1 0-2h8.491a3.013 3.013 0 0 0 2.885-2.176l1.585-5.55A1 1 0 0 0 19 5H6.74a3.007 3.007 0 0 0-2.82-2H3a1 1 0 0 0 0 2h.921a1.005 1.005 0 0 1 .962.725l.155.545v.005l1.641 5.742A3 3 0 0 0 7 18h12a1 1 0 0 0 0-2Zm-1.326-9l-1.22 4.274a1.005 1.005 0 0 1-.963.726H8.754l-.255-.892L7.326 7ZM16.5 19a1.5 1.5 0 1 0 1.5 1.5a1.5 1.5 0 0 0-1.5-1.5Z"
          />
        </symbol>
      </defs>
    </svg>

    <div class="product-item">
      <figure>
        <a :href="`/details/${product._id}`" :title="product.name">
          <img
            :src="`${settings.API}/file/products/${product._id}/${product.files[0]}`"
            :alt="product.name"
            class="product-image"
          />
        </a>
      </figure>
      <div class="d-flex flex-column text-center">
        <h3 class="fs-6 fw-normal">{{ product.name }}</h3>
        <div>
          <span class="rating">
            <svg width="18" height="18" class="text-warning">
              <use xlink:href="#star-full"></use>
            </svg>
            <svg width="18" height="18" class="text-warning">
              <use xlink:href="#star-full"></use>
            </svg>
            <svg width="18" height="18" class="text-warning">
              <use xlink:href="#star-full"></use>
            </svg>
            <svg width="18" height="18" class="text-warning">
              <use xlink:href="#star-full"></use>
            </svg>
            <svg width="18" height="18" class="text-warning">
              <use xlink:href="#star-half"></use>
            </svg>
          </span>
          <!-- <span>({{product.sales}})</span> -->
        </div>
        <div class="d-flex justify-content-center align-items-center gap-2">
          <span class="text-dark fw-semibold"
            >$ {{ product.currentValue.toLocaleString() }}</span
          >
        </div>
        <div class="p-3 pt-0">
          <div class="row g-1 mt-2">
            <div class="col-12">
              <router-link
                class="btn btn-primary rounded-1 p-2 btn-cart"
                :to="{ name: 'productDetails', params: { id: product._id } }"
              >
                <svg width="18" height="18"><use xlink:href="#cart"></use></svg>
                Comprar</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from "vue";
import settings from "@/config";

export default defineComponent({
  name: "Product",
  props: {
    product: { type: Object, required: true },
  },
  emits: ["click"],
  setup(props, { emit, expose }) {
    return { props, settings };
  },
});
</script>

<style scoped>
.product-image {
  /*min-width: 120px;
  min-height: 120px;
  max-height: 210px;
  max-width: 200px;*/
  height: 160px;
  width: 160px;
}
</style>
