<template>
  <header class="main-header">
    <div class="header-container">
      <div class="header-logo">
        <router-link :to="{ name: 'home' }">
          <img :src="logo" alt="logo" />
        </router-link>
      </div>

      <div class="header-search">
        <InputSearch />
      </div>

      <div style="margin-left: auto">
        <a href="/admin" class="button-rounded">
          <i class="fa-solid fa-user"></i>
        </a>
      </div>
    </div>

    <div class="d-none d-md-block">
      <div class="second-navbar-link">
        <div class="navbar-link">
          <router-link
            v-for="category in categories"
            :key="category._id"
            :to="{
              name: 'search',
              query: {
                categories: [category._id],
                status: [],
                search: null,
                price: [],
              },
            }"
            class="button-link"
          >
            {{ category.name }}
          </router-link>
          <router-link
            :to="{ name: 'contact' }"
            class="button-link"
            style="margin-left: auto"
          >
            <i class="fa-regular fa-envelope"></i> Contactanos | Quiero vender
          </router-link>
        </div>
      </div>
    </div>

    <div class="d-block d-md-none">
      <div class="second-navbar-link">
        <div class="navbar-link">
          <a class="button-link" @click.prevent="openCategories()">
            <i class="fa-solid fa-bars"></i> Categorias</a
          >
          <router-link
            :to="{ name: 'contact' }"
            class="button-link"
            style="margin-left: auto"
          >
            <i class="fa-regular fa-envelope"></i> Contactanos | Quiero vender
          </router-link>
        </div>
      </div>
    </div>

    <CategoriesList ref="categoriesListRef" />
  </header>
</template>

<script>
import logo from "@/assets/logo.png";
import CategoriesList from "@/components/Base/CategoriesList.vue";
import InputSearch from "@/components/Base/InputSearch.vue";
import { useCategoriesStore } from "@/store/useCategoriesStore.js";

import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  components: {
    InputSearch,
    CategoriesList,
  },
  setup() {
    const categoriesStore = useCategoriesStore();
    const categoriesListRef = ref(null);
    const openCategories = () => categoriesListRef.value.open();
    const categories = computed(() => categoriesStore.categories.slice(0, 4));
    return { categories, logo, categoriesListRef, openCategories };
  },
});
</script>

<style scoped>
.main-header {
  display: flex;
  position: fixed;
  height: 100px;
  z-index: 999;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 10px 10px -5px rgb(111 111 111 / 25%);
}

.second-navbar-link {
  background: #005ca9;
  display: flex;
  flex-direction: row;
}
.second-navbar-link .navbar-link {
  margin-left: 210px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.button-rounded {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d50713;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-right: 10px;
}

.button-rounded:hover {
  background-color: #a7070f;
}

.button-link {
  color: white;
  font-size: 0.885rem;
  font-family: var(--bs-body-font-family);
  letter-spacing: 0.0875rem;
  text-decoration: none;
  padding: 8px 40px 8px 40px;
  text-transform: capitalize;
}

.button-link:hover {
  background-color: #004e8d;
}

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .second-navbar-link .navbar-link {
    margin-left: 0px;
  }
  .button-link {
    color: white;
    font-size: 11px;
    font-family: var(--bs-body-font-family);
    letter-spacing: 0.07rem;
    text-decoration: none;
    padding: 5px 10px;
  }

  .button-rounded {
    margin: 2px;
  }
}
</style>
