<template>
  <div class="main-search p-2 rounded-3">
    <div class="main-search-button">
      <a
        class="btn nav-link d-none d-md-block"
        style="font-weight: bold"
        @click.prevent="openCategories()"
      >
        <i class="fa-solid fa-bars"></i> Categorias</a
      >
    </div>

    <div class="main-search-input">
      <div class="input-container">
        <input
          type="text"
          class="form-control border-0"
          placeholder="Encuentra productos, servicios y mas"
          v-model="searchValue"
          @keyup.enter="search"
        />
        <svg
          class="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          @click.prevent="search"
        >
          <path
            d="M15.5 14h-.79l-.28-.27a6.471 6.471 0 0 0 1.48-5.34C15.18 5.04 12.14 2 8.5 2S1.82 5.04 1.82 8.5 4.86 15 8.5 15c1.61 0 3.09-.59 4.24-1.55l.27.28v.79l4.25 4.25c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41l-4.25-4.25zM8.5 13c-2.48 0-4.5-2.02-4.5-4.5S6.02 4 8.5 4 13 6.02 13 8.5 10.98 13 8.5 13z"
          />
        </svg>
      </div>
    </div>

    <CategoriesList ref="categoriesListRef" />
  </div>
</template>

<script>
import CategoriesList from "@/components/Base/CategoriesList.vue";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "InputSearch",
  components: {
    CategoriesList,
  },
  setup() {
    const router = useRouter();
    const categoriesListRef = ref(null);
    const searchValue = ref(null);

    const search = (evt) => {
      if (searchValue.value && searchValue.value.length > 0) {
        router.push({
          name: "search",
          query: {
            categories: [],
            condition: [],
            search: searchValue.value,
            price: [],
          },
        });
      } else {
        router.push({
          name: "search",
          query: {
            categories: [],
            condition: [],
            search: null,
            price: [],
          },
        });
      }
    };

    const openCategories = () => categoriesListRef.value.open();

    return { search, openCategories, categoriesListRef, searchValue };
  },
});
</script>

<style scoped>
.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  width: 100%;
  padding-right: 30px; /* Espacio para el ícono */
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  fill: #1100ff; /* Color del ícono */
  cursor: pointer;
}

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .input-container input {
    font-size: 14px;
  }

  .search-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
