<template>
  <div class="layout">
    <Preloader v-if="loaderState.isLoading" />
    <Header />
    <div class="view-container">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import { loaderState } from "@/states/loaderState";
import Header from "@/components/Base/Header.vue";
import Footer from "@/components/Base/Footer.vue";
import Preloader from "@/components/Base/Preloader.vue";
import { useCategoriesStore } from "@/store/useCategoriesStore.js";

export default {
  components: {
    Preloader,
    Header,
    Footer,
  },
  setup() {
    const categoriesStore = useCategoriesStore();
    categoriesStore.loadCategories();
    return { loaderState };
  },
};
</script>
